import ApplicationController from "./application_controller";

// ファイル内でグローバルに使いたい変数。
let map
let markers = []
let infoWindows = []

// Connects to data-controller="google-map--index"
export default class extends ApplicationController {
    // ステートとして管理したい変数を設定。デフォルト値も先に入れておく。
    // locationのように階層化されたデーターはgetは出来るが下層データーを個別にsetできない。
    // pointsのような配列データーも１件ずつの登録変更削除はできず、配列そのものを再代入しかできない。
    static values = {
        mapCenterLat: Number,
        mapCenterLng: Number,
        zoom: 15,
        points: []
    };

    //　使うDomを変数に登録。 this.mapTarget で呼び出し。
    static targets = ["map"]

    // 読み込み時に実行する関数。
    connect() {
        this.setPoints()
        this.newMap()
    }

    newMap() {
        const loader = this.setLoader()
        loader.load().then(async () => {
            const {Map} = await google.maps.importLibrary("maps");
            const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
            // 地図を statis values の情報から作成
            map = new Map(this.mapTarget, {
                center: {lat: this.mapCenterLatValue, lng: this.mapCenterLngValue},
                zoom: this.zoomValue,
                mapId: '649445b522527620'
            })
            // 地図に全マーカーをセット
            this.addMarkersToMap()
        })
    }

    // ###地図にピンを追加する

    addMarkersToMap() {
        // 全ポイントをループで１件ずつ処理
        this.pointsValue.forEach((o, i) => {
            // markers　に 座標データー１件を追加
            this.addMarkerToMarkers(o)
            // infoWindowｓ に 吹き出しデーターを１件追加
            this.addInfoWindowToInfoWindows(o)
            // マーカーに該当する吹き出しを開くクリックイベントを追加
            this.addEventToMarker(i)
        })
    }

    addMarkerToMarkers(o) {
        // 引数の値からマーカーを１件作成
        const hazardPinElement = new google.maps.marker.PinElement({
            background: '#dc3545',
            borderColor: '#b12a38',
            glyphColor: '#ffffff',
            glyph: ''
        });
        const safetyPinElement = new google.maps.marker.PinElement({
            background: '#198754',
            borderColor: '#125d3a',
            glyphColor: '#ffffff',
            glyph: ''
        });
        const informationPinElement = new google.maps.marker.PinElement({
            background: '#0d6efd',
            borderColor: '#0a5ad2',
            glyphColor: '#ffffff',
            glyph: ''
        });

        this._marker = new google.maps.marker.AdvancedMarkerElement({
            position: {lat: o.lat, lng: o.lng},
            map: map,
            content: o.type === 'HAZARD' ? hazardPinElement.element : o.type === 'SAFETY' ? safetyPinElement.element : informationPinElement.element,
        })
        // markersに１件マーカーを追加
        markers.push(this._marker)
    }

    addInfoWindowToInfoWindows(o) {
        // 引数の値から吹き出しを１件作成
        // 吹き出しの中にはpoints/:idへのリンク
        // リンクには data-turbolinks="false" でリロードさせるとJS側のワーニングが出ない。
        this._infoWindow = new google.maps.InfoWindow({
            headerContent: o.type === 'HAZARD' ? '災害時に危険な場所' : o.type === 'SAFETY' ? '災害時に役に立つ場所' : 'その他の場所',
            content: `
        <div class="infoWindow pointInfoWindow">
        <div class="pointThumbnail"><img src="${o.photoUrl}"></div>
        <div class="pointDetail">
        <div class="pointText">
        <a href="/points/${o.id}" data-turbolinks="false">${o.title}</a>
        <p>${o.description}</p>
        </div>
        <div class="pointOwner">
        <p>by ${o.userName}<br/>（${o.createdAt}）</p>
        </div>
        </div>
        </div>
      `
        })
        // infoWindowに1件吹き出しを追加
        infoWindows.push(this._infoWindow)
    }

    addEventToMarker(i) {
        // i番目のマーカーにクリックイベントを追加
        markers[i].addListener('click', () => {
            // 同じインデックス番号iを吹き出しを開く
            infoWindows[i].open(map, markers[i]);
        });
    }

    setPoints() {
        this.pointsValue = JSON.parse(this.mapTarget.dataset.json)
    }
}
