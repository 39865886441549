import ApplicationController from "./application_controller";
import consumer from "../../channels/consumer";

let map
let markers = []
let infoWindows = []

// Connects to data-controller="google-map--index"
export default class extends ApplicationController {
    static values = {
        mapCenterLat: Number,
        mapCenterLng: Number,
        zoom: 15,
        fieldworkId: Number
    };

    static targets = ["map", "locations", "newLocation"]

    connect() {
        // Subscribe to CurrentLocationChannel
        this.channel = consumer.subscriptions.create(
            {channel: "CurrentLocationChannel", fieldwork_id: this.fieldworkIdValue},
            {
                received: data => {
                    //console.log(data.location)
                    this.addMarkerToMarkers(data.userName, JSON.parse(data.location))
                }
            }
        );
        // Setup a new map using Google Maps API
        this.newMap()
    }

    newMap() {
        const loader = this.setLoader()
        loader.load().then(async () => {
            const {Map} = await google.maps.importLibrary("maps");
            const {PinElement, AdvancedMarkerElement} = await google.maps.importLibrary("marker");
            // 地図を statis values の情報から作成
            map = new Map(this.mapTarget, {
                center: {lat: this.mapCenterLatValue, lng: this.mapCenterLngValue},
                zoom: this.zoomValue,
                mapId: '649445b522527620'
            })
            this.setLocationButton()
        })
    }

    addMarkerToMarkers(userName, location) {
        const userPinElement = new google.maps.marker.PinElement({
            background: '#dc3545',
            borderColor: '#b12a38',
            glyphColor: '#ffffff',
            glyph: ''
        });

        // Remove marker if it already exists
        this.removeMarkerFromMap(String(location.user_id))

        this._marker = new google.maps.marker.AdvancedMarkerElement({
            position: {lat: location.latitude, lng: location.longitude},
            map: map,
            title: String(location.user_id),
            content: userPinElement.element,
        })

        // Add marker to markers array
        markers.push(this._marker)

        // Add marker to map for display
        this.addMarkerToMap(userName, location, markers.length - 1)
    }

    addMarkerToMap(userName, location, index) {
        this.addInfoWindowToInfoWindows(userName, location)
        this.addEventToMarker(index)
    }

    removeMarkerFromMap(title) {
        markers.forEach(function (marker, i) {
            if (typeof marker != "undefined" && marker.title === title) {
                marker.setMap(null)
                markers[i] = undefined
                infoWindows[i] = undefined
            }
        });
    }

    addInfoWindowToInfoWindows(userName, location) {
        let createdAt = new Date(location.created_at)
        let dateTimeStr = createdAt.toLocaleDateString("ja-JP", {
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        })
        this._infoWindow = new google.maps.InfoWindow({
            headerContent: userName,
            content: `
        <div class="infoWindow">
          ${dateTimeStr}
        </div>
      `
        })
        infoWindows.push(this._infoWindow)
    }

    addEventToMarker(i) {
        // i番目のマーカーにクリックイベントを追加
        markers[i].addListener('click', () => {
            // 同じインデックス番号iを吹き出しを開く
            infoWindows[i].open(map, markers[i]);
        });
    }

    setLocationButton() {
        const locationButton = document.createElement("button");

        locationButton.textContent = "現在位置に移動";
        locationButton.classList.add("custom-map-control-button");
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
        locationButton.addEventListener("click", () => {
            // Try HTML5 geolocation.
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        map.setCenter(pos);
                    },
                    () => {
                        handleLocationError(true);
                    },
                );
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false);
            }
        });
    }
}

function handleLocationError(browserHasGeolocation) {
    console.log(
        browserHasGeolocation
            ? "Error: The Geolocation service failed."
            : "Error: Your browser doesn't support geolocation.",
    );
}