import {Controller} from "@hotwired/stimulus"
import {Loader} from "@googlemaps/js-api-loader"

export default class extends Controller {

    setLoader() {
        return new Loader({
            apiKey: "AIzaSyCcqs_T1DDaV1sEHOy2Vv3kvFWTKDOcpEU",
            version: "weekly",
        });
    }
}
